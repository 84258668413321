/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselPagination, CarouselTrigger } from "./common/pagination"
import { CarouselNavigation } from "./common/controls"

const CarouselTertiary = ({ children, customNavigationStyle,delayTime=2000 }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)
  const controllerStyle = {
    height: 25,
    width: 25,
    ...customNavigationStyle,
  }

  return (
    <div sx={{ position: "relative" }}>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        {children}
      </SwipeableViews>

      <div sx={{ display: "flex", justifyContent: "center" }}>
        <CarouselPagination
          containerStyle={{
            display: "inline-flex",
            mt: [12, 12, theme => theme.spacing.vertical.sm],
          }}
          triggerStyle={{
            lineHeight: 0,
            m: 0,
            mr: 2,
            ":last-child": { mr: 0 },
          }}
          trigger={CarouselTrigger}
        />
      </div>

      <CarouselNavigation
        nextControllerStyle={controllerStyle}
        previousControllerStyle={controllerStyle}
      />
    </div>
  )
}

CarouselTertiary.propTypes = { children: PropTypes.node.isRequired }

export default CarouselTertiary
