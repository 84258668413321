/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselPagination, CarouselTrigger } from "./common/pagination"
import { CarouselNavigation } from "./common/controls"

const CarouselSecondary = ({ children,delayTime=2000 }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)

  return (
    <div sx={{ position: "relative" }}>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        {children}
      </SwipeableViews>

      <CarouselPagination
        containerStyle={{
          bottom: 1,
          left: "50%",
          position: "absolute",
          transform: "translateX(-50%)",
        }}
        triggerStyle={{
          mr: 1,
          mb: 0,
          ":last-child": { mr: 0 },
        }}
        trigger={CarouselTrigger}
      />

      <CarouselNavigation
        nextControllerStyle={{ display: ["none", "block"] }}
        previousControllerStyle={{ display: ["none", "block"] }}
      />
    </div>
  )
}

CarouselSecondary.propTypes = { children: PropTypes.node.isRequired }

export default CarouselSecondary
