/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"

import theme from "../../gatsby-plugin-theme-ui"

const CarouselPrimary = ({ children ,delayTime=2000}) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)
  return (
    <React.Fragment>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
        axis="y"
        containerStyle={{
          height: `calc(100vh - ${theme.sizes.header.height}px)`,
          minHeight: 568,
        }}
      >
        {typeof children === "function" ? children(activeIndex) : children}
      </SwipeableViews>
    </React.Fragment>
  )
}

CarouselPrimary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.func.isRequired,
  ]),
}

export default CarouselPrimary
