/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useEffect } from "react"

import { useCarouselNavigation } from "./hooks"

import carouselArrow from "../../../images/carousel-arrow.svg"

export const CarouselNavigationButton = props => {
  const { customStyle, ...restProps } = props

  return (
    <button
      sx={{
        background: "none",
        border: "none",
        cursor: "pointer",
        fontSize: 5,
        p: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        ...customStyle,
      }}
      {...restProps}
    />
  )
}

export const CarouselNavigation = ({
  nextControllerStyle,
  previousControllerStyle,
  delayTime
}) => {
  const carouselNavigationButtonStyle = {
    backgroundColor: theme => theme.colors.backgrounds.primary,
    borderRadius: "50%",
    height: 40,
    width: 40,
  }
  const {
    onClickNext,
    onClickPrevious,
    showNext,
    showPrevious,
    autoScroll,
  } = useCarouselNavigation()

  useEffect(() => {
    let scrollInterval
    if (autoScroll) {
      scrollInterval = setInterval(() => {
        if (autoScroll) {
          onClickNext()
        }
        console.log("check")
      }, delayTime)
    }
    return () => {
      clearInterval(scrollInterval)
    }
  }, [])


  return (
    <React.Fragment>
      {showPrevious && (
        <CarouselNavigationButton
          customStyle={{
            ...carouselNavigationButtonStyle,
            left: "5%",
            ...previousControllerStyle,
          }}
          onClick={e => {
            e.preventDefault()
            onClickPrevious()
          }}
        >
          <img
            sx={{
              borderRadius: "50%",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
            }}
            src={carouselArrow}
            alt="Carousel Next Arrow"
          />
        </CarouselNavigationButton>
      )}

      {showNext && (
        <CarouselNavigationButton
          customStyle={{
            ...carouselNavigationButtonStyle,
            right: "5%",
            ...nextControllerStyle,
          }}
          onClick={e => {
            e.preventDefault()
            onClickNext()
          }}
        >
          <img
            sx={{
              borderRadius: "50%",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
              transform: "rotate(180deg)",
            }}
            src={carouselArrow}
            alt="Carousel Next Arrow"
          />
        </CarouselNavigationButton>
      )}
    </React.Fragment>
  )
}
