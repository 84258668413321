/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import SwipeableViews from "react-swipeable-views"

import { CarouselContext } from "./common/context"
import { CarouselPagination, CarouselTrigger } from "./common/pagination"
import { CarouselNavigation } from "./common/controls"

const CarouselQuaternary = ({ children, customNavigationStyle,delayTime=2000 }) => {
  const { activeIndex, setActiveIndex } = React.useContext(CarouselContext)
  const navigationStyle = {
    backgroundColor: theme => ["white", "white", theme.colors.tertiary],
    boxShadow: [
      "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
      "0px 0px 8px 0px rgba(0, 0, 0, 0.43)",
      "none",
    ],
    color: ["black", "black", "white"],
    fontSize: [14, 14, 24],
    height: [25, 25, 60],
    lineHeight: 0.5,
    width: [25, 25, 60],
    ...customNavigationStyle,
  }
  const triggerPosition = [
    `-${navigationStyle.width[1] / 2}px`,
    `-${navigationStyle.width[1] / 2}px`,
    theme => theme.spacing.horizontal,
    theme => theme.spacing.horizontal,
    theme => theme.spacing.horizontal,
    // theme => `-${navigationStyle.width[2] + theme.spacing.horizontal}px`,
    
  ]


  return (
    <div sx={{ position: "relative" }}>
      <SwipeableViews
        enableMouseEvents
        index={activeIndex}
        onChangeIndex={setActiveIndex}
        sx={{ pb: 1 }}
      >
        {children}
      </SwipeableViews>

      <div sx={{ display: "flex", justifyContent: "center" }}>
        <CarouselPagination
          containerStyle={{
            display: "inline-flex",
            mt: [12, 12, theme => theme.spacing.vertical.sm],
          }}
          triggerStyle={{
            lineHeight: 0,
            m: 0,
            mr: 2,
            ":last-child": { mr: 0 },
          }}
          trigger={CarouselTrigger}
        />
      </div>

      <CarouselNavigation
        previousControllerStyle={{
          display: ["none", "block"],
          ...navigationStyle,
          left: triggerPosition,
        }}
        nextControllerStyle={{
          display: ["none", "block"],
          ...navigationStyle,
          right: triggerPosition,
        }}
        delayTime={delayTime}
      />
    </div>
  )
}

CarouselQuaternary.propTypes = {
  action: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default CarouselQuaternary
