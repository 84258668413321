/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import { Box } from "@theme-ui/components"

import CarouselController from "./common/context"
import CarouselPrimary from "./carousel-primary"
import CarouselSecondary from "./carousel-secondary"
import CarouselTertiary from "./carousel-tertiary"
import CarouselQuaternary from "./carousel-quaternary"
import CarouselQuinary from "./carousel-quinary"

const Carousel = props => {
  const carouselType = props.type || "primary"
  const carouselTypeList = {
    primary: <CarouselPrimary {...props} />,
    secondary: <CarouselSecondary {...props} />,
    tertiary: <CarouselTertiary {...props} />,
    quaternary: <CarouselQuaternary {...props} />,
    quinary: <CarouselQuinary {...props} />,
  }

  return (
    <CarouselController
      autoScroll={props.autoScroll}
      totalSlides={React.Children.count(props.children)}
    >
      <Box sx={{ position: "relative", ...props.customStyle }}>
        {carouselTypeList[carouselType] && carouselTypeList[carouselType]}
      </Box>
    </CarouselController>
  )
}

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.object,
  customNavigationStyle: PropTypes.object,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "quaternary",
    "quinary",
  ]),
}

export default Carousel
