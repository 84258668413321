import React, { useEffect, useState } from "react"

import { CarouselContext } from "./context"

export function useCarouselNavigation() {
  const {
    activeIndex,
    setActiveIndex,
    totalSlides,
    autoScroll,
  } = React.useContext(CarouselContext)
  // useEffect(()=>{
  //   if(autoScroll){
  //     setInterval( ()=>{
  //        changeIndex()
  //       //  console.log("set interval called")
  //     },2000)
  //   }
  // },[])

  //  const changeIndex = React.useCallback(
  //  ()=>{
  //       //  let newIndex = (activeIndex+1)%(totalSlides-1);
  //       //   setActiveIndex(newIndex)
  //       // if(totalSlides-1>activeIndex){
  //         handleNextSlide()
  //       //   console.log("newIndex" ,activeIndex,totalSlides)
  //       // }else {
  //         handleStarIndexSlide()
  //       //   console.log("secondone else")
  //       // }

  //  })

  const handlePreviousSlide = React.useCallback(() => {
    if (activeIndex) {
      setActiveIndex(currentActiveIndex => currentActiveIndex - 1)
    }
  }, [setActiveIndex, activeIndex])

  const handleNextSlide = React.useCallback(() => {
    if (activeIndex < totalSlides - 1) {
      setActiveIndex(currentActiveIndex => currentActiveIndex + 1)
    }
  }, [setActiveIndex, activeIndex, totalSlides])

  const handleStarIndexSlide = React.useCallback(() => {
    if (activeIndex === totalSlides && autoScroll) {
      setActiveIndex(0)
    }
  }, [setActiveIndex, activeIndex])

  handleStarIndexSlide()

  return React.useMemo(
    () => ({
      onClickNext: handleNextSlide,
      onClickPrevious: handlePreviousSlide,
      showNext: activeIndex < totalSlides - 1,
      showPrevious: activeIndex > 0,
      autoScroll: autoScroll,
    }),
    [handlePreviousSlide, handleNextSlide, activeIndex, totalSlides]
  )
}
